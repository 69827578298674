/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-expressions */
/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import debounce from 'lodash/debounce';
// import noop from 'lodash/noop';


// Relatives
import http from '../../services/api/http';
import UserContext from '../../contexts/UserContext';
import AppContext from '../../contexts/AppContext';


const ClaimingMixedRequest = props => {
    let cancelToken;
    const { nextUri } = props;
    const { getUser, logout, setUserStatus, getNGOs } = useContext(UserContext);
    const user = getUser();
    const { id, token } = user;
    const sessionDataBradcaster = { virtual_artist_user_id: id };
    const { apiHost } = useContext(AppContext);
    const [deployListClaiming, setDeployListingClaiming] = useState([]);
    const [ownershipList, setOwnershipList] = useState([]);
    const [searchByName, setSearchByName] = useState('');
    const [showAll, setShowAll] = React.useState();
    const [lockClaimBtn, setLockClaimBtn] = useState(false);
    const [claimBroadcasterId, setClaimBroadcasterId] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [nextBtnEnabled, setNextBtnEnaled] = useState('visually-hidden');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    

    const listingData = async state => {
        let yoursRequest = '';
        setLoading(true);

        if(state){
            yoursRequest = `&virtual_artist_user_id=${sessionDataBradcaster.virtual_artist_user_id}`;
        }


        if(typeof cancelToken !== typeof undefined){
            cancelToken.cancel("Operation canceled due to new request.");
        }

        cancelToken = axios.CancelToken.source();

        try {
            const response = await http(apiHost, token.accessToken, token.tokenType)
            .get(`/api/web/v2/broadcasters?page_size=10&virtual_artist_stage_name=${searchByName}${yoursRequest}`, {
                cancelToken: cancelToken.token
            });
            setDeployListingClaiming(response.data.output.data);
            setShowAll(state);
            if(response){
                setLoading(false);
            }

        } catch (error) {
             // console.log(error);
        }

        
    }

    // const debouncelistingData = useCallback(debounce(listingData, 600), []);

    const listingOwnershipData = async () => {
        const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/v2/ownership-change-request`);
        setOwnershipList(response.data.output.data);
    }

    const statusMethod = status => (
        <div className='w-100'>
            { status !== sessionDataBradcaster.virtual_artist_user_id && status !== 2 && status === 1 && <span className="badge bg-success">Unmanaged <AddModeratorIcon  /></span>}
            { status === 2 && <span className="badge bg-primary">Requested <HourglassTopIcon /></span>}
            { status === sessionDataBradcaster.virtual_artist_user_id && <span className="badge bg-light text-dark">Managed <GppGoodIcon /></span>}
            { status !== 1 && status !== 2 && status !== sessionDataBradcaster.virtual_artist_user_id && <span className="badge bg-light text-dark">Managed <GppGoodIcon /></span>}
        </div>
    );

    const verifiedStatus = BroadcasterId => {
        return (ownershipList.some(request => {
            return BroadcasterId === request.model_id && request.is_completed === false && request.is_approved === false
        }));
    }

    const haveYours = () => {
        if((ownershipList.some(request => {
            return request.requested_by === sessionDataBradcaster.virtual_artist_user_id 
            && request.is_completed === true 
            && request.is_approved === true
        }))) {
            setNextBtnEnaled('show');
            setUserStatus('active');
        }
    } 

    const handleClaim = async () => {
           const response = await http(apiHost, token.accessToken, token.tokenType)
                                  .post(`/api/web/v2/ownership-change-request`, {
                                      type: 'broadcaster',
                                      model_id: claimBroadcasterId
                                  });

           if(response){
            listingData();
            listingOwnershipData();
            setLockClaimBtn(false);
           }
           
           return
    }

    const debounceHandleSearch = useCallback(debounce(setSearchByName, 600), []);

    useEffect(() => {
        haveYours();
    });

    useEffect(() => {
            listingData();
            listingOwnershipData();
    }, [searchByName]);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = BroadcasterId => {
        setOpen(false);
        if(BroadcasterId > 0){
            handleClaim();
        }
      };

    return (
        <Box key='ClaimingMixedRequest-window' className='ClaimingMixedRequest'>
            <h1>
                Stations Claiming Requests
            </h1>
            <div key='ClaimingMixedRequest-window-header' className='ClaimingMixedRequest__header'>
                <TextField onChange={input => {
                    debounceHandleSearch(input.target.value);
                }} id="outlined-basic" label="Search by Name" variant="outlined" />
            </div>
            <div key='ClaimingMixedRequest-window-body' className='ClaimingMixedRequest__body'>
                <table key='ClaimingMixedRequest-window-body-table' className="table">
                    <thead key='ClaimingMixedRequest-window-body-table-thead'>
                        <tr key='ClaimingMixedRequest-window-body-table-thead-tr'>
                        <th key='ClaimingMixedRequest-window-body-table-thead-th-entity' scope="col">Licensee</th>
                        <th key='ClaimingMixedRequest-window-body-table-thead-th-station' className="text-start" scope="col">Station</th>
                        <th key='ClaimingMixedRequest-window-body-table-thead-th-frequency' className="text-start" scope="col">Frequency</th>
                        <th key='ClaimingMixedRequest-window-body-table-thead-th-status' className="text-center" scope="col">Status</th>
                        <th key='ClaimingMixedRequest-window-body-table-thead-th-actions' scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody key='ClaimingMixedRequest-window-body-table-tbody'>
                        { deployListClaiming.length > 0 && loading === false &&  deployListClaiming.map(item => (

                        <tr key={`ClaimingMixedRequest-window-body-table-tbody-tr-${item.id}`}>
                            <td className='text-start' key={`ClaimingMixedRequest-window-body-table-tbody-tr-${item.id}-td-entity`}>
                                {item._related.broadcasting_company.name}
                            </td>
                            <td key={`ClaimingMixedRequest-window-body-table-tbody-tr-${item.id}-td-station`} className="text-start">
                                {item.virtual_artist_stage_name}
                            </td>
                            <td key={`ClaimingMixedRequest-window-body-table-tbody-tr-${item.id}-td-station-dial`}>
                                {item.station_dial}
                            </td>
                            <td key={`ClaimingMixedRequest-window-body-table-tbody-tr-${item.id}-td-status`} className="text-center">
                                {verifiedStatus(item.id) === true ? statusMethod(2):
                                statusMethod(item.virtual_artist_user_id)}
                            </td>
                            <td key={`ClaimingMixedRequest-window-body-table-tbody-tr-${item.id}-td-actions`}>
                            <Button className={verifiedStatus(item.id) === true || item.virtual_artist_user_id === sessionDataBradcaster.virtual_artist_user_id ? 'visually-hidden' : 'show'} onClick={() => {
                                setClaimBroadcasterId(item.id);
                                handleClickOpen();
                            }}  variant="outlined">
                                Manage
                                <AddModeratorIcon  />
                            </Button>
                            </td>
                        </tr>

                        )) }
                        { loading === false && deployListClaiming.length <= 0 && <tr>
                            <td key={`ClaimingMixedRequest-window-body-table-tbody-tr-td-response`} colSpan='4'>
                                <a onClick={() => {
                                    listingData(false);
                                    listingOwnershipData();
                                }}>
                                    <h3 key={`ClaimingMixedRequest-window-body-table-tbody-tr-td-response-title`}>
                                        {`Click here to manage your stations`}
                                    </h3>
                                </a>
                            </td>
                        </tr> }
                        { loading === true && <tr>
                            <td key={`ClaimingMixedRequest-window-body-table-tbody-tr-td-loading`} className='text-center' colSpan='5'>
                                <h3 key={`ClaimingMixedRequest-window-body-table-tbody-tr-td-loading-icon`}>
                                    <CircularProgress />
                                </h3>
                            </td>
                        </tr> }
                    </tbody>
                </table>
            </div>
            <div key={`ClaimingMixedRequest-window-footer`} className='ClaimingMixedRequest__footer'>
                <Button key={`ClaimingMixedRequest-window-footer-btn-logout`} onClick={() => {
                        logout();
                        navigate('/');
                    }} variant="outlined">
                        Logout
                </Button>
                { loading === false && 
                    <div key={`ClaimingMixedRequest-window-footer-btns-groups`} className="btn-group" role="group" aria-label="Actions-footer">
                        <Button key={`ClaimingMixedRequest-window-footer-btns-groups-show-all`} className={`btn btn-primary ${!showAll ? 'active' : 'normal'}`} onClick={() => {
                                    listingData(false);
                                    listingOwnershipData();
                            }} variant="outlined">
                                Show All
                        </Button>
                        <Button key={`ClaimingMixedRequest-window-footer-btns-groups-show-my-broadcasters`} className={`btn btn-primary ${showAll ? 'active' : 'normal'}`} onClick={() => {
                                    listingData(true);
                                    listingOwnershipData();
                            }} variant="outlined">
                                Show my stations
                        </Button>
                    </div>
                }
                { loading === true && 
                    <div key={`ClaimingMixedRequest-window-footer-btns-groups`} className="btn-group" role="group" aria-label="Actions-footer">
                        <Button key={`ClaimingMixedRequest-window-footer-btns-groups-show-all`} className={`btn btn-dark disabled`} variant="outlined">
                                Show All
                        </Button>
                        <Button key={`ClaimingMixedRequest-window-footer-btns-groups-show-my-broadcasters`} className={`btn btn-dark disabled`} variant="outlined">
                                Show my stations
                        </Button>
                    </div>
                }
                <Button key={`ClaimingMixedRequest-window-footer-btns-groups-next`} className={nextBtnEnabled} onClick={() => {
                    if(nextUri !== ''){
                        getNGOs();
                        navigate(nextUri);
                    }
                }} variant="outlined">
                    Next
                    <ArrowForwardIosIcon  />
                </Button>
            </div>
            <Dialog
                key={`ClaimingMixedRequest-window-dialog`}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle key={`ClaimingMixedRequest-window-dialog-title`} id="alert-dialog-title">
                    Are you sure to claim this Station?
                </DialogTitle>
                <DialogContent key={`ClaimingMixedRequest-window-content`}>
                <DialogContentText key={`ClaimingMixedRequest-window-dialog-description`} id="alert-dialog-description">
                    please allow up to 2 business to review your request. If {`there's`} an emergency or you need additional help please contact us at <a href='mailto:insider@picklejar.com'>insider@picklejar.com</a>
                </DialogContentText>
                </DialogContent>
                <DialogActions key={`ClaimingMixedRequest-window-dialog-actions`}>
                <Button key={`ClaimingMixedRequest-window-dialog-no`} onClick={() => {
                    handleClose(0);
                }} disabled={lockClaimBtn}>Exit Out</Button>
                <Button  key={`ClaimingMixedRequest-window-dialog-yes`} onClick={() => {
                    if(!lockClaimBtn){
                        handleClose(claimBroadcasterId);
                        setLockClaimBtn(true);
                    }
                }} disabled={lockClaimBtn}  autoFocus>
                    Proceed
                </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );

}

ClaimingMixedRequest.defaultProps = {
    nextUri: '/'
  };
  
ClaimingMixedRequest.propTypes = {
    nextUri: PropTypes.string
  };

export default ClaimingMixedRequest;