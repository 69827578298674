// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Box, Button, Typography, Stepper, Step, StepButton } from '@mui/material';
import moment from 'moment';
import { omit, get, isNil } from 'lodash';
import PropTypes from 'prop-types';

// Relatives
import Layout from '../../layouts/Layout/Layout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import FundraiserStep1 from './createSteps/FundraiserStep1';
import FundraiserStep2 from './createSteps/FundraiserStep2';
import FundraiserStep3 from './createSteps/FundraiserStep3';
import FundraiserStep4 from './createSteps/FundraiserStep4';

const FundraiserCreate = props => {
  const { updateMode } = props;
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(updateMode);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [stepLoading, setStepLoading] = useState(false);
  const [cache, setCache] = useState({});
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const navigate = useNavigate();

  const fundraiserForm = useForm({
    defaultValues: {
      // step 1
      name: '',
      message: '',
      // step 2
      country: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip: '',
      date_base: '',
      date_from: '',
      date_to: '',
      // step 3
      target_goal: '1000',
      // step 4
      media_id: '',
      banner_id: ''
    }
  });

  const steps = [
    {
      label: 'Fundraiser details',
      content: <FundraiserStep1 updateMode={updateMode}
      cache={cache}
      setCache={setCache}
      />
    },
    {
      label: 'Location',
      content: <FundraiserStep2 updateMode={updateMode} cache={cache} />
    },
    {
      label: 'Set your Fundraiser Goal',
      content: <FundraiserStep3/>
    },
    {
      label: 'Add a cover photo',
      content: <FundraiserStep4 
      setStepLoading={setStepLoading} 
      cache={cache} 
      setCache={setCache}
      setUploadLoading={setUploadLoading} />
    }
  ];

  const handleStep = step => async () => {
    if (await fundraiserForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async () => {
    if (await fundraiserForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = data => {
    if (activeStep !== steps.length - 1) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  // const handleDraft = async (/* formData */) => {};

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }
    
    setLoading(true);
    const { date_base: dateBase, date_from: dateFrom, date_to: dateTo } = formData;
    const timeStart =
      moment(dateBase)
        .set({ hour: dateFrom.getHours(), minute: dateFrom.getMinutes(), second: dateFrom.getSeconds() })
        .utc()
        .valueOf() / 1000;
    const timeEnd =
      moment(dateBase)
        .set({ hour: dateTo.getHours(), minute: dateTo.getMinutes(), second: dateTo.getSeconds() })
        .utc()
        .valueOf() / 1000;
    let response;

    let idNew;
    if(!isNil(id)){
      idNew = Number(id); 
    }    
    
    if(formData.address_manual_mode === true){
      // pre-data for formated address
    const address1 = !isNil(formData.street_address) ? `${formData.street_address}, ` : '';
    const address2 = !isNil(formData.street_address_2) ? `${formData.street_address_2}, ` : '';
    // new formated address
    const formattedAddress =  `${address1}${address2}${formData.city}, ${formData.state}, ${formData.zip}, ${formData.country}`;
    formData = {
      ...formData,
      id: idNew,
      target_goal: Number(formData.target_goal),
      formatted_address: formattedAddress,
      skip_address_lookup: 1
    }
    }else{
      formData = {
        ...formData,
        id: idNew,
        target_goal: Number(formData.target_goal)
      }
    }

    if (!updateMode) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v1/events/new-event', {
          ...omit(formData, ['date_from', 'date_to']),
          datesStartEnd: [`${timeStart}, ${timeEnd}`],
          // artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'association-fundraiser',
          status: 1 // 1 = publish, 4 = draft
        })
        .catch(() => {});
    } else if (updateMode && id) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v1/events/update-event', {
          ...omit(formData, ['date_from', 'date_to', 'full_address']),
          hour_date_start: timeStart,
          hour_date_end: timeEnd,
          // artist_ids: [userNGOSelected.virtual_artist_id],
          event_type: 'association-fundraiser',
          status: 1 // 1 = publish, 4 = draft
        })
        .catch(() => {});
    } else {
      console.log('Something is wrong');
    }

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, error } = get(response, 'data', {});
    if (!success && error) {
      error.fieldErrors.forEach(({ field, message }) =>
        fundraiserForm.setError(field, { type: 'required', message }, { shouldFocus: true })
      );

      return;
    }

    navigate('/fundraisers');
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/events/${id}`)
      .catch(() => {});

    const { success, output, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    const {
      name,
      message,
      country,
      city,
      state,
      zip,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      formatted_address: fullAddress,
      target_goal: targetGoal,
      media_id: avatarId,
      banner_id: bannerId,
      _related
    } = output;

    const fromDate = get(output, '_related.event_dates.0.start_date');
    const toDate = get(output, '_related.event_dates.0.end_date');
    const imageOriginal = get(output, '_misc.avatar.original_image');
    const bannerOriginal = get(output, '_misc.banner.original_image');
    const lat = get(output, 'lat');
    const lon = get(output, 'lon');

    fundraiserForm.reset({
      // step 1
      name,
      message,
      association: (_related.beneficiary_non_profit_ngos.length > 0 ? _related.beneficiary_non_profit_ngos[0].name : ''),
      // step 2
      formatted_address: fullAddress,
      country,
      street_address: streetAddress,
      street_address_2: streetAddress2,
      city,
      state,
      zip,
      date_base: fromDate ? new Date(moment(fromDate * 1000).format('MM/DD/YYYY')) : '',
      date_from: fromDate ? new Date(moment(fromDate * 1000)) : '',
      date_to: toDate ? new Date(moment(toDate * 1000)) : '',
      // step 3
      target_goal: String(targetGoal),
      // step 4
      media_id: avatarId,
      banner_id: bannerId
    });

    setCache({ media_id: imageOriginal, banner_id: bannerOriginal, lat, lon });

    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/fundraisers');
  };

  useEffect(() => {
    if (updateMode && id) {
      loadModel();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if(uploadLoading){
      setLoading(true);
    }

    if(!uploadLoading){
      setLoading(false);
    }

}, [uploadLoading]);

  return (
    <Layout
      title="Create Fundraiser"
      breadcrumb={['Home', <Link to="/fundraisers">Fundraisers</Link>, 'Create Fundraiser']}
    >
      <div className="fundraiser-create">
        <div className="d-flex flex-column col-sm-8 align-items-center justify-content-center mx-auto">
          {activeStep > 0 && (
            <Typography color="primary" variant="h5" className="fundraiser-title-name text-right mx-auto">
              {`Fundariser: ${fundraiserForm.getValues('name')}`}
            </Typography>
          )}
          <Stepper nonLinear activeStep={activeStep} className={`py-4 align-self-stretch ${uploadLoading ? 'v-hidden' : 'show'}`}>
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <FormProvider {...fundraiserForm}>
            <Box
              component="form"
              onSubmit={fundraiserForm.handleSubmit(stepperSubmit)}
              className="align-self-stretch"
              autoComplete="off"
            >
              <div className="d-flex flex-column align-items-stretch">
                <Typography color="primary" variant="h1" className="text-center mx-auto">
                  {steps[activeStep].label}
                </Typography>
                {steps[activeStep].content}
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  className="btn-forms"
                  disabled={activeStep === 0 || uploadLoading}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep + 1 === steps.length ? (
                  <Button type="submit" variant="outlined" className="btn-forms" disabled={loading || stepLoading}>
                    {updateMode ? 'Update and Publish' : 'Save and Publish'}
                  </Button>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    {/* <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleDraft}
                    >
                      Save as Draft
                    </Button> */}

                    <Button
                      sx={{ mr: 1 }}
                      id="nextBtn"
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </FormProvider>
        </div>
      </div>
    </Layout>
  );
};

FundraiserCreate.defaultProps = {
  updateMode: false
};

FundraiserCreate.propTypes = {
  updateMode: PropTypes.bool
};

export default FundraiserCreate;
