// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, TextField, Typography } from '@mui/material';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment';

// Relatives
import AddressLookup from '../../../components/AddressLookup';

const EventsStep2 = props => {
  const { updateMode, cache } = props;
  const { control, getValues } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <AddressLookup cache={cache} />
      </Grid>
      <Typography color="primary" variant="h1" className="text-center mx-auto">
        Date & Time
      </Typography>
      <Grid item xs={12} className="d-flex">
        <Controller
          name="date_from"
          control={control}
          rules={{
            required: 'From is required',
            validate: {
              dateInvalid: value => {
                const diff = moment(value, 'MM/DD/YYYY', true).diff(moment(new Date().setUTCHours(0, 0, 0, 0)));

                if (!updateMode && diff < 0) {
                  return 'This date is invalid';
                }

                return !moment(value, 'MM/DD/YYYY', true).isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                {...field}
                label="From"
                minDate={new Date()}
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <Controller
          name="date_to"
          control={control}
          rules={{
            required: 'To is required',
            validate: {
              dateInvalid: value => {
                const momentDate = moment(value, 'MM/DD/YYYY', true);
                const diff = momentDate.diff(moment(new Date().setUTCHours(0, 0, 0, 0)));
                const diffFrom = momentDate.diff(getValues('date_from'));

                return diff < 0 || diffFrom < 0 || !momentDate.isValid() ? 'This date is invalid' : true;
              }
            }
          }}
          render={({ field, fieldState }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                {...field}
                label="To"
                format="MM/dd/yyyy"
                clearable
                ampm={false}
                minDate={new Date()}
                invalidDateMessage="End date is required"
                renderInput={props => (
                  <TextField
                    {...props}
                    variant="standard"
                    className="flex-grow-1"
                    helperText={
                      fieldState.error && (
                        <Typography variant="p" color="error">
                          {fieldState.error.message}
                        </Typography>
                      )
                    }
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />
      </Grid>
    </>
  );
};

EventsStep2.defaultProps = {
  updateMode: false,
  cache: {}
};

EventsStep2.propTypes = {
  updateMode: PropTypes.bool,
  cache: PropTypes.object
};

export default EventsStep2;
