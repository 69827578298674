// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  ListSubheader
} from '@mui/material';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import noop from 'lodash/noop';

// Relatives
import http from '../../../../services/api/http';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext/UserContext';
import PhoneInput from '../../../../components/PhoneInput/PhoneInput';
import AddressLookup from '../../../../components/AddressLookup';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const OrganisationStep2 = props => {
  const { setLoading } = props;
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [classificationType, setClassificationType] = useState([]);
  const [organizationSizes, setOrganizationSizes] = useState([]);
  const [organizationScopes, setOrganizationScopes] = useState([]);
  const { control, setValue } = useFormContext();

  const initTypes = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get('/api/web/v1/ngo/ngo-types-tree')
      .catch(() => {});

    if (!response || !response.data) {
      return;
    }

    const {
      data: { data }
    } = response;
    const types = Object.keys(data).reduce(
      (acum, typeKey) => [
        ...acum,
        {
          label: typeKey,
          subTypes: Object.keys(data[typeKey]).map(subTypeKey => ({
            value: subTypeKey,
            label: get(data, `${typeKey}.${subTypeKey}`)
          }))
        }
      ],
      []
    );

    setClassificationType(types);
  };

  const initSizes = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get('/api/web/v1/ngo/ngo-sizes')
      .catch(() => {});

    if (!response || !response.data) {
      return;
    }

    const {
      data: { data }
    } = response;

    setOrganizationSizes(Object.keys(data));
  };

  const initScopes = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get('/api/web/v1/ngo/ngo-scopes')
      .catch(() => {});

    if (!response || !response.data) {
      return;
    }

    const {
      data: { data }
    } = response;

    setOrganizationScopes(Object.keys(data));
  };

  const handleChange = fieldOnChange => address => {
    if (!address) {
      fieldOnChange('');

      return;
    }

    const {
      fullAddress,
      streetNumber,
      streetName,
      postalCode,
      stateName,
      locality,
      occupancyIdentifier,
      countryCode,
      coordinates
    } = address;

    setValue('country', countryCode);
    setValue('street_address', `${streetNumber || ''} ${streetName || ''}`);
    setValue('street_address_2', occupancyIdentifier);
    setValue('city', locality);
    setValue('state', stateName);
    setValue('zip', postalCode);
    setValue('lat', coordinates.latitude);
    setValue('lon', coordinates.longitude);

    fieldOnChange(fullAddress);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([initTypes(), initSizes(), initScopes()]).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="formatted_address"
          control={control}
          rules={{ required: 'Address is required' }}
          render={({ field, fieldState }) => {
            const { value, onChange } = field;

            return (
              <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Address
                </label>
                <AddressLookup onChange={handleChange(onChange)} value={value} placeholder="Write your address...">
                  <Grid item xs={12}>
                    <Controller
                      name="street_address"
                      control={control}
                      rules={{ required: 'Address is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address"
                          variant="standard"
                          placeholder="Street Address or P.O. Box"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="street_address_2"
                      control={control}
                      // rules={{ required: 'Address 2 is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Address 2"
                          variant="standard"
                          placeholder="Apt, Suite, Unit Building (optional)"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="d-flex">
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: 'City is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="City"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="state"
                      control={control}
                      rules={{ required: 'State is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="State"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="zip"
                      control={control}
                      rules={{ required: 'Zip is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          label="Zip"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: 'Country is required' }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Country"
                          variant="standard"
                          helperText={
                            fieldState.error && (
                              <Typography variant="p" color="error">
                                {fieldState.error.message}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </Grid>
                </AddressLookup>
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="main_phone"
          control={control}
          rules={{
            required: 'Main Phone is required',
            validate: {
              checkUser: value => {
                if (value.length > 5) {
                  return true;
                }

                return 'Main Phone is invalid';
              }
            }
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              label="Main Phone"
              variant="standard"
              InputProps={{
                inputComponent: PhoneInput,
                inputProps: {
                  name: field.name
                }
              }}
              helperText={
                fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="size"
          control={control}
          rules={{ required: 'Organisation Size is required' }}
          render={({ field, fieldState }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel id="organization-size">Organization Size</InputLabel>
              <Select {...field} labelId="organization-size" id="organization-size" label="Organization Size*">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {organizationSizes.map((size, index) => {
                  return (
                    <MenuItem value={size} key={`type-${index}`}>
                      {size}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="scope"
          control={control}
          rules={{ required: 'Organization Scope is required' }}
          render={({ field, fieldState }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel id="organization-scope">Organization Scope</InputLabel>
              <Select {...field} labelId="organization-scope" id="organization-scope" label="Organization Scope*">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {organizationScopes.map((scope, index) => {
                  return (
                    <MenuItem value={scope} key={`type-${index}`}>
                      {scope}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          disabled
          name="non_profit_ngo_subtype_id"
          control={control}
          rules={{ required: 'Organization Type is required' }}
          render={({ field, fieldState }) => (
            <FormControl variant="standard" fullWidth>
              <InputLabel id="organization-type">Organization Type</InputLabel>
              <Select
                {...field}
                labelId="organization-type"
                id="organization-type"
                label="Organization Type *"
                MenuProps={{
                  PaperProps: {
                    style: {
                      overflow: 'auto',
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
                    }
                  }
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {classificationType.map(type => [
                  <ListSubheader>{type.label}</ListSubheader>,
                  ...type.subTypes.map((item, index) => (
                    <MenuItem value={item.value} key={`subType-${index}`} style={{ paddingLeft: '32px' }}>
                      {`Child Option - ${item.label}`}
                    </MenuItem>
                  ))
                ])}
              </Select>
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};

OrganisationStep2.defaultProps = {
  setLoading: noop
};

OrganisationStep2.propTypes = {
  setLoading: PropTypes.func
};

export default OrganisationStep2;
