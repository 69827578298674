// Packages
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import get from 'lodash/get';
import qs from 'querystring';
import CircularProgress from '@mui/material/CircularProgress';

// Relatives
import Layout from '../../layouts/Layout/Layout';
import CampaignFilter from './components/CampaignFilter';
import CampaignList from './components/CampaignList';
import AppContext from '../../contexts/AppContext';
import http from '../../services/api/http';
import UserContext from '../../contexts/UserContext';

const CampaignIndex = () => {
  const [campaigns, setCampaings] = useState([]);
  const [artistId, setArtistId] = useState('');
  const [campaignStatus, setCampaignStatus] = useState(1);
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(1);
  const [urlParams, setUrlParams] = useSearchParams();
  const [disableScroll, setDisableScroll] = useState(false);

  const mapCampaign = campaignRaw => {
    const { id, name, formatted_address: formattedAddress, goal_completion_percentage: goalProgress } = campaignRaw;
    const fromDate = get(campaignRaw, '_related.event_dates.0.start_date');
    const toDate = get(campaignRaw, '_related.event_dates.0.end_date');
    const eventPerformances = get(campaignRaw, '_related.event_performances');
    const avatarImage = get(campaignRaw, '_misc.avatar.large_image');
    const bgImage = get(campaignRaw, '_misc.banner.large_image');

    return {
      id,
      name,
      address: formattedAddress,
      goalProgress,
      participants: eventPerformances.map(participant => {
        const {
          _related: { artist, band }
        } = participant;

        if (artist) {
          const { handle } = artist;
          const largeImage = get(artist, '_misc.avatar.large_image');

          return {
            avatarImage: largeImage || null,
            name: handle
          };
        }

        if (band) {
          const { handle } = band;
          const largeImage = get(band, '_misc.avatar.large_image');

          return {
            avatarImage: largeImage || null,
            name: handle
          };
        }

        return {
          avatarImage: null,
          name: null
        };
      }),
      fromDate: fromDate * 1000,
      toDate: toDate * 1000,
      bgImage: bgImage || null,
      avatarImage: avatarImage || null
    };
  };

  const performSearch = async (page = 1, pageSize = 10, append = false) => {
    const params = urlParams.toString();
    setLoading(true);
    const userArtistIds = [];
    userArtistIds.push(userNGOSelected.virtual_artist_id);

    if(artistId > 0){
      userArtistIds.push(artistId);
    }

    const ArtistIdSelected = userArtistIds.length > 1 ? userArtistIds.join(',') : userNGOSelected.virtual_artist_id;

    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(
        `/api/web/v2/events?page=${page}&page_size=${pageSize}&artists_match_mode=full&event_type=association-campaign&status=${campaignStatus}&artist_ids=${ArtistIdSelected}${params === '' ? '' : `&${params}`}`
      )
      .catch(() => {});

    setLoading(false);
    const { success, error, output } = get(response, 'data', {});
    if (!success) {
      console.warn(error);

      return;
    }

    if(output.data.length > 0){
      if (append) {
        setCampaings(state => [...state, ...output.data.map(campaignRaw => mapCampaign(campaignRaw))]);
      } else {
        setCampaings(output.data.length > 0 ? output.data.map(campaignRaw => mapCampaign(campaignRaw)) : []);
      }

      if (output.data.length < pageSize && !disableScroll) {
        setDisableScroll(true);
      } else if (output.data.length >= pageSize && disableScroll) {
        setDisableScroll(false);
      }
    }else{
      setCampaings([]);
    }

  };

  useEffect(() => {
    if (userNGOSelected) {
      performSearch();
    }
  }, [urlParams, campaignStatus, userNGOSelected, artistId]);

  const handleClickStatus = status => () => setCampaignStatus(status);

  const handleClickSearch = params => {
    setUrlParams(params);
  };

  const handleLoadMore = async () => {
    performSearch(pageOffset + 1, 10, true);
    setPageOffset(state => state + 1);
  };

  const filterValues = useMemo(() => qs.parse(urlParams.toString()), [urlParams]);

  return (
    <Layout title="Campaigns" breadcrumb={['Home', 'Campaigns']}>
      <div className="campaign-index d-flex align-items-start">
        <div className="col-12 col-sm-4">
          <Link to="/campaigns/create" className="campaign-create-btn btn btn-primary">
            CREATE
          </Link>
          <CampaignFilter value={filterValues} onSearch={handleClickSearch} disabled={loading} artistUserData={data => {
            setArtistId(data.id);
          }} />
        </div>
        <div className="d-flex flex-column col-12 col-sm-8">
          <div className="campaign-status">
            <div
              className={classNames('campaign-status__item', { active: campaignStatus === 1 })}
              onClick={handleClickStatus(1)}
            >
              ACTIVE
            </div>
            <div
              className={classNames('campaign-status__item', { active: campaignStatus === 4 })}
              onClick={handleClickStatus(4)}
            >
              DRAFT
            </div>
            <div
              className={classNames('campaign-status__item', { active: campaignStatus === 3 })}
              onClick={handleClickStatus(3)}
            >
              TRASH
            </div>
          </div>
          {!loading && (
            <CampaignList
              canAutoScroll={!disableScroll}
              containerNode={document}
              onCallback={handleLoadMore}
              className="flex-grow-1"
              items={campaigns}
              loading={loading}
            />
          )}
          {loading && (
          <div className='fundraiser-list'>
            <div className="list-empty">
              <CircularProgress />
            </div>
          </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CampaignIndex;
