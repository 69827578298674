import React from 'react';
import PropTypes from 'prop-types';

// Relatives
import BroadcasterCreate from './BroadcasterCreate';

const BroadcasterIndex = props => {

    const { updateMode } = props;


    return(
          <BroadcasterCreate updateMode={updateMode} />
    )


}


BroadcasterIndex.defaultProps = {
  updateMode: false 
}

BroadcasterIndex.propTypes = {
  updateMode: PropTypes.bool
}

export default BroadcasterIndex;