/* eslint-disable camelcase */
// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Typography, Stepper, Step, StepButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import PropTypes from 'prop-types';

// Relatives
import Layout from '../../layouts/Layout/Layout';
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import BroadcasterStep1 from './createSteps/BroadcasterStep1';

const BroadcasterCreate = props => {
  const { updateMode } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [loading, setLoading] = useState(updateMode);
  const [stepLoading, setStepLoading] = useState(false);
  const [cache, setCache] = useState({});
  const { apiHost } = useContext(AppContext);
  const { token, userNGOSelected } = useContext(UserContext);
  const navigate = useNavigate();

  // console.log(userNGOSelected, id);
  const campaignForm = useForm({
    defaultValues: {
      // step 1
      virtual_artist_bio: '',
      virtual_artist_main_picture_id: '',
      virtual_artist_banner_id: ''
    }
  });

  const steps = [
    {
      label: 'Broadcaster Profile',
      content: <BroadcasterStep1 setStepLoading={setStepLoading} cache={cache} setCache={setCache} />
    }
  ];

  const handleStep = step => async () => {
    if (await campaignForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async () => {
    if (await campaignForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = data => {
    if (activeStep !== steps.length - 1) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }
    
    setLoading(true);

    const response = await http(apiHost, token.accessToken, token.tokenType)
        .patch(`/api/web/v2/broadcasters/${userNGOSelected.id}`, formData)
        .catch(() => {});
    

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success: success2, error } = get(response, 'data', {});
    if (!success2 && error) {
      console.log(error);
      console.log(error.fieldErrors);
      error.fieldErrors.forEach(({ field, message }) =>
        campaignForm.setError(field, { type: 'required', message }, { shouldFocus: true })
      );

      return;
    }

    navigate('/campaigns');
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/broadcasters/${userNGOSelected.id}`)
      .catch(() => {});

    const { success, output, error } = get(response, 'data', {});
    // console.log(output, 'events');
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    const {
      virtual_artist_bio,
      virtual_artist_main_picture_id,
      virtual_artist_banner_id
    } = output;

    const imageOriginal = get(output, '_misc.avatar.medium_image');
    const bannerOriginal = get(output, '_misc.banner.original_image');

    campaignForm.reset({
      // step 1
      virtual_artist_bio,
      virtual_artist_main_picture_id,
      virtual_artist_banner_id
    });

    setCache({ virtual_artist_main_picture_id: imageOriginal, virtual_artist_banner_id: bannerOriginal });
    setLoading(false);
  };

  const handleCancel = () => {
    navigate('/campaigns');
  };

  useEffect(() => {
    if (updateMode) {
      loadModel();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Layout title="Create Campaign" breadcrumb={['Home', 'Broadcaster', 'profile']}>
      <div className="campaign-create">
        <div className="d-flex flex-column col-sm-8 align-items-center justify-content-center mx-auto">
          {activeStep > 0 && (
            <Typography color="primary" variant="h5" className="campaign-title-name text-right mx-auto">
              {`Campaign: ${campaignForm.getValues('name')}`}
            </Typography>
          )}
          <Stepper nonLinear activeStep={activeStep} className="py-4 align-self-stretch visually-hidden">
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <FormProvider {...campaignForm}>
            <Box
              component="form"
              onSubmit={campaignForm.handleSubmit(stepperSubmit)}
              className="align-self-stretch"
              autoComplete="off"
            >
              <div className="d-flex flex-column align-items-stretch">
                <Typography color="primary" variant="h1" className="text-center mx-auto">
                  {steps[activeStep].label}
                </Typography>
                {steps[activeStep].content}
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="primary"
                  variant="outlined"
                  className="btn-forms"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep + 1 === steps.length ? (
                  <Button type="submit" variant="outlined" className="btn-forms" disabled={loading || stepLoading}>
                    {updateMode ? 'Update and Publish' : 'Save and Publish'}
                  </Button>
                ) : (
                  <>
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    {/* <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleDraft}
                    >
                      Save as Draft
                    </Button> */}
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      variant="outlined"
                      className="btn-forms"
                      disabled={loading || stepLoading}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </FormProvider>
        </div>
      </div>
    </Layout>
  );
};

BroadcasterCreate.defaultProps = {
  updateMode: false
};

BroadcasterCreate.propTypes = {
  updateMode: PropTypes.bool
};

export default BroadcasterCreate;
