import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

// Relatives
import BasicPopover from '../../../components/BasicPopover';
import ImageCropInput from '../../../components/ImageCropInput';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import http from '../../../services/api/http';

import logo from '../../../styles/images/logo.png';
import banner from '../../../styles/images/campaignDefault.png';

const BroadcasterStep1 = props => {

    const { setStepLoading, cache, setCache } = props;
    const [loadingAvatar, setLoadingAvatar] = useState(false);
    const [loadingBanner, setLoadingBanner] = useState(false);
    const { control, setError, setValue } = useFormContext();
    const { apiHost } = useContext(AppContext);
    const { token } = useContext(UserContext);
  
    const handleSubmit = async (callbackLoading, input, file) => {
      if (!token) {
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);
      callbackLoading(true);
      const response = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' })
        .post('/api/web/media/manage/upload', formData)
        .catch(() => {});
  
      callbackLoading(false);
      if (!response || !response.data) {
        return;
      }
  
      const { success, output } = get(response, 'data', {});
      if (!success && output) {
        Object.keys(output).forEach(field => setError(field, { type: 'required', message: get(output, `${field}.0`) }));
  
        return;
      }
  
      setValue(input, get(response, 'data.data.0.id', ''));
      setCache(state => ({ ...state, [input]: get(response, 'data.data.0.original') }));
    };
  
    useEffect(() => {
      setStepLoading(loadingAvatar || loadingBanner);
    }, [loadingAvatar, loadingBanner]);
  
    const onUpload = callbackLoading => (name, data) => handleSubmit(callbackLoading, name, data.file);
  
    let bannerLarge = banner;
    if (cache.virtual_artist_banner_id) {
      bannerLarge = cache.virtual_artist_banner_id;
    }
  
    let avatarLarge = logo;
    if (cache.virtual_artist_main_picture_id) {
      avatarLarge = cache.virtual_artist_main_picture_id;
    }
  
    return (
      <div>
          <Grid item xs={12}>
          <div className="event__header">
        <div className="header__banner" style={{ backgroundImage: `url(${bannerLarge})` }}>
          <Controller
            name="virtual_artist_banner_id"
            control={control}
            rules={{ required: 'Banner is required' }}
            render={({ field, fieldState }) => (
              <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                <ImageCropInput
                  name={field.name}
                  onUpload={onUpload(setLoadingBanner)}
                  aspect={16 / 9}
                  showPreview={false}
                />
                <FormHelperText>
                  {fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )}
                </FormHelperText>
              </div>
            )}
          />
        </div>
        <div className="header__banner-details">
          <div
            className={classNames('event__avatar', { 'default-avatar': !cache.virtual_artist_main_picture_id })}
            style={{ backgroundImage: `url(${avatarLarge})` }}
          >
            <Controller
              name="virtual_artist_main_picture_id"
              control={control}
              rules={{ required: 'Avatar is required' }}
              render={({ field, fieldState }) => (
                <div className="image-crop-input MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
                  <ImageCropInput
                    name={field.name}
                    onUpload={onUpload(setLoadingAvatar)}
                    aspect={1}
                    showPreview={false}
                  />
                  <FormHelperText>
                    {fieldState.error && (
                      <Typography variant="p" color="error">
                        {fieldState.error.message}
                      </Typography>
                    )}
                  </FormHelperText>
                </div>
              )}
            />
          </div>
        </div>
      </div>
              </Grid>
          <Grid className='mt-5' item xs={12}>
        <Controller
          name="virtual_artist_bio"
          control={control}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Biography
                </label>
                <BasicPopover message="This is what the public will see." />
              </div>
              <TextField
                {...field}
                fullWidth
                // label="Campaign Name"
                variant="standard"
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            </div>
          )}
        />
      </Grid>
      </div>
    );


}


BroadcasterStep1.defaultProps = {
  setStepLoading: noop,
  cache: null,
  setCache: noop
}

BroadcasterStep1.propTypes = {
  setStepLoading: PropTypes.func,
  cache: PropTypes.object,
  setCache: PropTypes.func
}

export default BroadcasterStep1;