// Packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

// Relatives
// import Notifications from './Notifications'; // for later
import AvatarUser from './AvatarUser';
import ProfileMenu from '../Menu/ProfileMenu';
import NavigationMenu from '../Menu/NavigationMenu';

const Header = props => {
  const { user, specialHeader } = props;
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);

  const handleMenuClose = () => setIsMenuMobileOpen(false);

  const handleMenuOpen = () => setIsMenuMobileOpen(true);

  const { userNGOs, setUserNGO } = user;

  return (
    <AppBar position="static" className="bg-dark main-navigation">
      <Drawer anchor="right" open={isMenuMobileOpen} onClose={handleMenuClose} className="drawer-main-navigation">
        <div className="drawer-main-navigation-header">
          <div className="brand">
            <img src="/images/pjl.png" alt="logo" loading="lazy" />
          </div>
          <IconButton className="drawer-main-navigation-close" onClick={handleMenuClose} color="primary">
            <CloseIcon />
          </IconButton>
        </div>
        { !specialHeader &&
          <NavigationMenu />
        }
        <ProfileMenu specialMenu={specialHeader} isMobile ngos={userNGOs}
         onClose={handleMenuClose} onSelectNGO={setUserNGO} />
      </Drawer>

      <Toolbar className="justify-content-between">
        <div className="navigation-start">
          <div className="brand">
            <img src="/images/logo.png" alt="logo" loading="lazy" />
          </div>
          {/* <div className="stage-name text-link">{stageName}</div> */}
        </div>

        <Box component="nav" className="navigation-center d-none d-xxl-flex">
        { !specialHeader &&
          <NavigationMenu />
        }
        </Box>
        <Box component="nav" className="navigation-end">
          {/* <Notifications /> */}
          <AvatarUser Special={specialHeader} user={user} />
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={handleMenuOpen}
            className="btn-open-drawer d-xxl-none"
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.defaultProps = {
  children: null,
  isLogged: false,
  specialHeader: false,
  user: null,
  onClickBurger: noop
};

Header.propTypes = {
  children: PropTypes.node,
  isLogged: PropTypes.bool,
  specialHeader: PropTypes.bool,
  user: PropTypes.object,
  onClickBurger: PropTypes.func
};

export default Header;
