/* eslint-disable no-unneeded-ternary */
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import axios from 'axios';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import http from '../../services/api/http';
import UserContext from '../../contexts/UserContext/UserContext';
import AppContext from '../../contexts/AppContext';

import logo from '../../styles/images/logo.png';

const emptyString = '';

const SelectNGOModal = props => {
  // Base
  let cancelToken;
  const { data, setNameProp, required } = props;
  const { getUser } = useContext(UserContext);
  const user = getUser();
  const { token } = user;
  const { apiHost } = useContext(AppContext);
  // Requests
  const [ngoName, setNgoName] = useState('');
  const [virtualArtistId, setVirtualArtistId] = useState(0);
  const [virtualArtistUserId, setVirtualArtistUserId] = useState(0);
  const [listngos, setListNgos] = useState([]);
  const [expand, setExpand] = useState(false);

  const getNgoList = async (sort = 'id', find = '') => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }

    cancelToken = axios.CancelToken.source();

    try {
      const response = await http(apiHost, token.accessToken, token.tokenType).get(
        `/api/web/v2/non-profit-ngos?sort=id${find !== '' ? `&${sort}=${find}` : ''}`,
        {
          cancelToken: cancelToken.token
        }
      );
      setListNgos(response.data.output.data);
      // console.log(response.data.output.data, 'NGOS')
    } catch (error) {
      // console.log(error);
    }
  };

  const handleFindNGO = name => {
    getNgoList('name', name);
    setExpand(true);
  };

  const debounceHandleFindNGO = useCallback(debounce(handleFindNGO, 600), []);

  const handleSelectNGO = (virtualArtistId, virtualArtistUserId, newNgoName, ngoId, Settings) => {
    setNgoName(newNgoName);
    setVirtualArtistId(virtualArtistId);
    setVirtualArtistUserId(virtualArtistUserId);
    setExpand(false);
    data({ virtualArtistId, virtualArtistUserId, newNgoName, ngoId, Settings });
  };

  const handleClearInput = () => {
    getNgoList('name', emptyString);
    setNgoName(emptyString);
    data({ virtualArtistId: 0, virtualArtistUserId: 0, newNgoName: '', ngoId: 0, Settings: 0 });
  };

  // run onload
  useEffect(() => {
    if (listngos.length <= 0) {
      getNgoList();
    }

    if (setNameProp !== '') {
      setNgoName(setNameProp);
    }
  }, []);

  const handleToggle = () => {
    setExpand(expand ? false : true);
  }

  useEffect(() => {
    getNgoList();
  }, [virtualArtistId, virtualArtistUserId]);

  return (
    <div onClick={handleToggle} key="SelectNGO" className="SelectNGO w-100 mt-3">
        <>
        <input placeholder="Select an association" style={{ cursor: 'pointer' }} value={ngoName} />
        <a style={{ top: '0px', right: '0px' }}
            onClick={() => {
              handleClearInput();
            }}
            className={`SelectNGO_btn-clear ${ngoName.length > 0 ? 'show' : 'visually-hidden'}`}
          >
            <BackspaceIcon />
          </a>
        </>
      <Modal className='modal-filter' toggle={handleToggle} isOpen={expand}>
        <ModalHeader className='text-center' toggle={handleToggle}>
          <h3>Pick an association</h3>
        </ModalHeader>
        <ModalBody>
          <div>
          <input
            onChange={input => {
              // handleFindNGO(debounce(input.target.value, 600));
              debounceHandleFindNGO(input.target.value);
              setNgoName(input.target.value);
            }}
            key="SelectNGO_text-input"
            className="form-select"
            type="text"
            value={ngoName}
            placeholder="Pick an association"
            required={required}
          />
          <a
            onClick={() => {
              handleClearInput();
            }}
            className={`SelectNGO_btn-clear ${ngoName.length > 0 ? 'show' : 'visually-hidden'}`}
          >
            <BackspaceIcon />
          </a>
          </div>
          <hr />
          <div key="SelectNGO_list" style={{ maxHeight: '350px' }} className="SelectNGO__list overflow-auto">
            {listngos.length > 0 &&
              listngos.map(NGO => (
                <Button
                  className='w-100 justify-content-start'
                  key={`SelectNGO_item_${NGO.virtual_artist_id}`}
                  onClick={() => {
                    handleSelectNGO(NGO.virtual_artist_id, NGO.virtual_artist_user_id, NGO.name, NGO.id, NGO);
                  }}
                >
                  <img onError={event => {
                      event.target.src = logo;
                  }} src={`${apiHost}${NGO._misc.avatar.small_image}`} style={{ width: '46px' }} className="img-thumbnail me-2" alt="..." />
                  <span className="text-dark">{NGO.name}</span>
                </Button>
              ))}
            {listngos.length <= 0 && <Button>No results found</Button>}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

SelectNGOModal.defaultProps = {
  data: noop,
  setNameProp: '',
  required: false
};

SelectNGOModal.propTypes = {
  data: PropTypes.func,
  setNameProp: PropTypes.string,
  required: PropTypes.bool
};

export default SelectNGOModal;