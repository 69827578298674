// Packages
import React from 'react';

// Relatives
import Layout from '../../layouts/Layout';
import Broadcaster from '../Login/steps/Broadcaster';

const BroadcasterClaim = () => {
  return (
    <Layout specialLayout title="Station Claiming Requests" breadcrumb={['Home', 'Station Claiming Requests']}>
      <Broadcaster />
    </Layout>
  );
};

export default BroadcasterClaim;