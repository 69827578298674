// Packages
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Container, Typography, CircularProgress, Stepper, Step, StepButton, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import get from 'lodash/get';

// Relatives
import http from '../../../../services/api/http';
import AppContext from '../../../../contexts/AppContext';
import UserContext from '../../../../contexts/UserContext/UserContext';
import OrganisationStep1 from './OrganisationStep1';
import OrganisationStep2 from './OrganisationStep2';
import OrganisationStep3 from './OrganisationStep3';

const Organisation = props => {
  const { onSubmit, updateMode, id, onCancel } = props;
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(updateMode);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const organisationForm = useForm({
    defaultValues: {
      // step 1
      name: '',
      virtual_artist_handle: '',
      nickname: '',
      about: '',
      mission: '',
      // step 2
      formatted_address: '',
      country: '',
      street_address: '',
      street_address_2: '',
      city: '',
      state: '',
      zip: '',
      lat: '0',
      lon: '0',
      main_phone: '+1',
      size: '',
      scope: '',
      non_profit_ngo_subtype_id: '',
      // step 3
      ein: '',
      bank_account_bank_name: '',
      bank_account_holder_name: '',
      bank_account_swift_code: '',
      bank_account_iban_number: ''
    }
  });

  const steps = [
    {
      label: 'Let Us Know More About You',
      content: <OrganisationStep1 />
    },
    {
      label: 'Let Us Locate You',
      content: <OrganisationStep2 setLoading={setLoading} />
    },
    {
      label: "Let's Get You Your  Funds!",
      content: <OrganisationStep3 />
    }
  ];

  const handleStep = step => async () => {
    if (await organisationForm.trigger()) {
      setActiveStep(step);
    }
  };

  const handleBack = () => setActiveStep(state => state - 1);

  const handleNext = async e => {
    e.stopPropagation();
    e.preventDefault();
    if (await organisationForm.trigger()) {
      setActiveStep(state => state + 1);
    }
  };

  const stepperSubmit = async data => {
    if (activeStep !== steps.length - 1 && (await organisationForm.trigger())) {
      setCompleted(state => ({ ...state, [activeStep]: true }));
      setActiveStep(activeStep + 1);
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async formData => {
    if (!formData || !token) {
      return;
    }

    let response;
    if (updateMode) {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .patch(`/api/web/v2/non-profit-ngos/${id}`, formData)
        .catch(() => {});
    } else {
      response = await http(apiHost, token.accessToken, token.tokenType)
        .post('/api/web/v2/non-profit-ngos', formData)
        .catch(() => {});
    }

    setLoading(false);
    if (!response || !response.data) {
      return;
    }

    const { success, output } = get(response, 'data', {});
    if (!success && output) {
      let pageToBack = 0;
      Object.keys(output).forEach(field => {
        organisationForm.setError(field, { type: 'required', message: get(output, `${field}.0`) });
        if (['name', 'virtual_artist_handle', 'nickname', 'about', 'mission'].includes(field)) {
          pageToBack = 0;
        } else if (
          [
            'ein',
            'bank_account_bank_name',
            'bank_account_holder_name',
            'bank_account_swift_code',
            'bank_account_iban_number'
          ].includes(field)
        ) {
          pageToBack = 2;
        }
      });
      setActiveStep(pageToBack);

      return;
    }

    onSubmit(formData);
  };

  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/non-profit-ngos/${id}`)
      .catch(() => {});

    const { success, output, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }

    organisationForm.reset({
      ...pick(output, [
        // step 1
        'name',
        'virtual_artist_handle',
        'nickname',
        'about',
        'mission',
        // step 2
        'formatted_address',
        'country',
        'street_address',
        'street_address_2',
        'city',
        'state',
        'zip',
        'lat',
        'lon',
        'main_phone',
        'size',
        'scope',
        'non_profit_ngo_subtype_id',
        // step 3
        'ein',
        'bank_account_bank_name',
        'bank_account_holder_name',
        'bank_account_swift_code',
        'bank_account_iban_number'
      ])
    });

    setLoading(false);
  };

  useEffect(() => {
    if (updateMode && id) {
      loadModel();
    }
  }, []);

  return (
    <div className="organisation">
      <div className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </div>
      <Container maxWidth="sm">
        <Box sx={{ width: '100%', margin: '16px' }}>
          <Stepper nonLinear activeStep={activeStep} className="py-4">
            {steps.map(({ label }, index) => (
              <Step key={label} completed={completed[index]} className="hide-sm-label">
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {/* {label} */}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <div>
            <FormProvider {...organisationForm}>
              <Box component="form" onSubmit={organisationForm.handleSubmit(stepperSubmit)} autoComplete="off">
                <Grid container>
                  <Typography color="primary" variant="h1" className="text-center">
                    {steps[activeStep].label}
                  </Typography>
                  {steps[activeStep].content}
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    className="btn-forms"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {updateMode && (
                    <Button type="button" variant="outlined" className="btn-forms me-4" onClick={onCancel}>
                      Cancel
                    </Button>
                  )}
                  {activeStep + 1 === steps.length ? (
                    <Button type="submit" variant="outlined" className="btn-forms">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      sx={{ mr: 1 }}
                      color="primary"
                      type="button"
                      onClick={handleNext}
                      variant="outlined"
                      className="btn-forms"
                    >
                      Next
                    </Button>
                  )}
                </Box>
              </Box>
            </FormProvider>
          </div>
        </Box>
      </Container>
    </div>
  );
};

Organisation.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false
};

Organisation.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool
};

export default Organisation;
