// Packages
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop, get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Relatives
import BasicPopover from '../../../components/BasicPopover';
import UserContext from '../../../contexts/UserContext';
import SelectNGO from '../../../components/SelectNGO/SelectNGOModal';

const blank = '';

const CampaignStep1 = props => {
  const { updateMode, cache, setCache } = props;
  const { control, errors, register, setValue, getValues } = useFormContext();
  const { userNGOSelected } = useContext(UserContext);
  const defaultValueSelected = getValues('association') !== blank ? getValues('association') : blank;
  const [backSelected, setBackSelected] = useState(defaultValueSelected);
  const navigate = useNavigate();
  
  const handleClick = () => {
    navigate('/fundraisers/create');
  };


  useEffect(() => {
    setValue('association', getValues('association'));
    setValue('ngoId', getValues('ngoId'));
    setValue('artist_ids[0]', getValues('artist_ids[0]'));
    setValue('artist_ids[1]', getValues('artist_ids[1]'));
    setValue('additional_event_admins', getValues('additional_event_admins'));
    setBackSelected(getValues('association'));
  }, [backSelected]);

  return (
    <>
      <Typography className="inline-block text-muted">
        <span className="mb-4">
          Campaign are meant to run for a multi-Day period of time with a specific “Funding Goal.”
        </span>
        <span className="mb-4">
          If you want to create a single day event, please{' '}
          <a className="text-primary pointer" onClick={handleClick}>
            click here
          </a>{' '}
          to create a Fundraiser.
        </span>
      </Typography>
      {!updateMode && <Grid item xs={12}>
              <SelectNGO setNameProp={backSelected} data={data => {
                setValue('association', data.newNgoName);
                setValue('ngoId', data.ngoId);
                setValue('artist_ids[0]', userNGOSelected.virtual_artist_id);
                setValue('artist_ids[1]', data.virtualArtistId);
                setValue('additional_event_admins', data.virtualArtistUserId);
                setCache({
                  ...cache,
                  banner: {
                  image: get(data.Settings, '_misc.banner.original_image'),
                  id: get(data.Settings, 'banner_id')
                  }
                  });
              }} />
              <div className='visually-hidden'>
                <TextField {...register("association", { required: 'Association is required' })} type="hidden" />
                <TextField {...register("ngoId")} type='hidden' />
                <TextField {...register("artist_ids[0]")} type='hidden' />
                <TextField {...register("artist_ids[1]")} type='hidden' />
                <TextField {...register("additional_event_admins")} type='hidden' />
              </div>
              
              <ErrorMessage
                errors={errors}
                name="association"
                render={({ message }) =>  (
                  <Typography style={{ fontSize: "0.75rem !important" }} className='w-100 text-left mt-2 mb-2 f-special-075rem' variant="p" color="error">
                    {message}
                  </Typography>
                )}
              />
      </Grid>}
      {updateMode && <Grid item xs={12}>
          <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root disabled">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Organization Name
                </label>
              </div>
              <TextField inputProps={{
                readOnly: true
              }} {...register("association")} variant="standard" />
          </div>
      </Grid>}
      <Grid className='mt-4' item xs={12}>
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Campaign name
                </label>
                <BasicPopover message="This is what the public will see." />
              </div>
              <TextField
                {...field}
                fullWidth
                // label="Campaign Name"
                variant="standard"
                helperText={
                  fieldState.error && (
                    <Typography variant="p" color="error">
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            </div>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="message"
          control={control}
          rules={{ required: 'Message is required' }}
          render={({ field, fieldState }) => (
            <div className="text-area MuiInput-root MuiInputBase-colorPrimary MuiFormControl-root">
              <div className="d-flex flex-row bd-highlight">
                <label className="MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-standard MuiFormLabel-root MuiFormLabel-colorPrimary">
                  Why are you Raising Money?
                </label>
                <BasicPopover message="Tell people about your organization, mission, and campaign goal." />
              </div>
              <TextareaAutosize {...field} maxLength={255} minRows={3} maxRows={6} label="Mission" variant="standard" />
              <FormHelperText>
                {fieldState.error && (
                  <Typography variant="p" color="error">
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormHelperText>
            </div>
          )}
        />
      </Grid>
    </>
  );
};

CampaignStep1.defaultProps = {
  updateMode: false,
  cache: {},
  setCache: noop
};

CampaignStep1.propTypes = {
  updateMode: PropTypes.bool,
  cache: PropTypes.string,
  setCache: PropTypes.func
};

export default CampaignStep1;
