import React from 'react';

// Relatives
import ClaimingMixedRequest from '../../../../components/ClaimingMixedRequest/index';

const Broadcaster = () => {

    return (
        <div className='Broacaster'>
            <ClaimingMixedRequest />
        </div>
    );

}

export default Broadcaster;